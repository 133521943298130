.search-form {
    display: flex;
    flex-direction: column;
  }
  
  .search-form input {
    font: 16px arial, sans-serif;
    line-height: 34px;
    background-color: #fff;
    padding: 0.5rem;
    vertical-align: top;
    border: none;
    outline: none;
    border-radius: 2px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
    transition: box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1);
    margin-bottom: 1rem;
  }
  
  .search-form input:focus {
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.08);
  }
  
  button {
    background-color: #f2f2f2;
    border: 1px solid #f2f2f2;
    border-radius: 2px;
    color: #757575;
    cursor: default;
    font-family: arial, sans-serif;
    font-size: 13px;
    font-weight: bold;
    margin: 11px 4px;
    min-width: 54px;
    padding: 0 16px;
    text-align: center;
    height: 36px;
    line-height: 27px;
  }
  